html, body, #root {
    margin: 0;
    height: 100%
}

.App {
    height: 100%;
}

.corner-cut {
    position: relative;
    overflow: hidden;
}

.corner-cut:after {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    background: #dc3545;
    position: absolute;
    top: 0px;
    right: -5px;
    transform: skewX(45deg);
}

.zindex-sticky-plus-one {
    z-index: 1021;
}

/* The following allows modals within modals to work well (parent modal is greyed out when child modal is shown) */
div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2){
    z-index: 1100;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4){
    z-index: 1050;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6){
    z-index: 1000;
}

.cursor-pointer{
    cursor: pointer;
}

.pre-wrap{
    white-space: pre-wrap;
}

.text-purple{
    color: purple;
}